import React, { useState } from "react";
import Menu from "../common/Menu";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import Modal from "./Modal";

const RadioStationDesktop = ({ data }) => {
  const [openModal, setOpenModal] = useState(false);
  const [audioUrl, setAudioUrl] = useState("");

  function handleClick(imgData) {
    if (imgData.isModal) {
      setAudioUrl(imgData.audio.asset.url);
      setOpenModal(true);
    } else {
      window.open(imgData.externalUrl, "_blank");
    }
  }

  function handleClose() {
    setOpenModal(false);
  }

  return (
    <div className="radio_station">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-4 col-md-4">
            <Link to="/">
              <GatsbyImage
                image={data.back.asset.gatsbyImageData}
                alt={data.back.asset.altText || "Back"}
                className="radio_station_tatoo1 img-fluid"
                loading="eager"
                objectFit="contain"
              />
            </Link>
          </div>
          <div className="col-lg-4 col-md-4">
            <center>
              <Link to="/">
                <GatsbyImage
                  image={data.logo.asset.gatsbyImageData}
                  alt={data.logo.asset.altText || "Gubler Land"}
                  className="radio_station_tatoo2 img-fluid"
                  loading="eager"
                  objectFit="contain"
                />
              </Link>
            </center>
            <br />
            <center>
              <GatsbyImage
                image={data.logo2.asset.gatsbyImageData}
                alt={data.logo2.asset.altText || "Kgube Radio"}
                className="radio_station_tatoo3 img-fluid"
                loading="eager"
                objectFit="contain"
              />
            </center>
          </div>
          <div className="col-lg-4 col-md-4">
            {/*Burger Menu Start-*/}
            <Menu />
            {/*Burger Menu End-*/}
            <GatsbyImage
              image={data.musicIcon1.asset.gatsbyImageData}
              alt={data.musicIcon1.asset.altText || "Music Icon 1"}
              className="radio_station_tatoo12 img-fluid"
              style={{
                cursor: "pointer",
              }}
              loading="eager"
              objectFit="contain"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-5 col-md-5">
            <div
              onClick={() => handleClick(data.poePoems)}
              data-toggle="modal"
              style={{
                cursor: "pointer",
              }}
              data-target="#tell_tale_heart"
            >
              <GatsbyImage
                image={data.poePoems.image.asset.gatsbyImageData}
                alt={data.poePoems.image.asset.altText || data.poePoems.title}
                className="radio_station_tatoo5 img-fluid"
                loading="eager"
                objectFit="contain"
              />
            </div>
            <div
              onClick={() => handleClick(data.anabelLee)}
              data-toggle="modal"
              style={{
                cursor: "pointer",
              }}
              data-target="#anabel-lee"
            >
              <GatsbyImage
                image={data.anabelLee.image.asset.gatsbyImageData}
                alt={data.anabelLee.image.asset.altText || data.anabelLee.title}
                className="radio_station_tatoo16 img-fluid"
                loading="eager"
                objectFit="contain"
              />
            </div>
          </div>
          <div
            className="col-lg-2 col-md-2"
            style={{
              cursor: "pointer",
            }}
          >
            <center>
              <GatsbyImage
                image={data.musicIcon2.asset.gatsbyImageData}
                alt={data.musicIcon2.asset.altText || "Music Icon 2"}
                className="radio_station_tatoo6 img-fluid"
                loading="eager"
                objectFit="contain"
              />
            </center>
          </div>
          <div className="col-lg-5 col-md-5">
            <div className="tone_right">
              <div
                onClick={() => handleClick(data.toonDeaf)}
                data-toggle="modal"
                data-target="#cheers"
                style={{
                  cursor: "pointer",
                }}
              >
                <GatsbyImage
                  image={data.toonDeaf.image.asset.gatsbyImageData}
                  alt={data.toonDeaf.image.asset.altText || data.toonDeaf.title}
                  className="radio_station_tatoo7 img-fluid"
                  loading="eager"
                  objectFit="contain"
                />
              </div>
              <div
                onClick={() => handleClick(data.goldenGirls)}
                data-toggle="modal"
                data-target="#golden-girls"
                style={{
                  cursor: "pointer",
                }}
              >
                <GatsbyImage
                  image={data.goldenGirls.image.asset.gatsbyImageData}
                  alt={
                    data.goldenGirls.image.asset.altText ||
                    data.goldenGirls.title
                  }
                  className="radio_station_tatoo15 img-fluid"
                  loading="eager"
                  objectFit="contain"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-4">
            <div
              onClick={() => handleClick(data.singAlone)}
              data-toggle="modal"
              style={{
                cursor: "pointer",
              }}
              data-target="#what-are-you-halloween"
            >
              <GatsbyImage
                image={data.singAlone.image.asset.gatsbyImageData}
                alt={data.singAlone.image.asset.altText || data.singAlone.title}
                className="radio_station_tatoo8 img-fluid"
                loading="eager"
                objectFit="contain"
              />
            </div>
          </div>
          <div className="col-lg-2 col-md-2">
            <center>
              {" "}
              <div
                onClick={() => handleClick(data.dontTouch)}
                data-toggle="modal"
                data-target="#gube-is-a-jerk"
                style={{
                  cursor: "pointer",
                }}
              >
                <GatsbyImage
                  image={data.dontTouch.image.asset.gatsbyImageData}
                  alt={
                    data.dontTouch.image.asset.altText || data.dontTouch.title
                  }
                  className="radio_station_tatoo9 img-fluid"
                  loading="eager"
                  objectFit="contain"
                />
              </div>
            </center>
          </div>
          <div className="col-lg-3 col-md-3">
            <div
              onClick={() => handleClick(data.podcast)}
              style={{
                cursor: "pointer",
              }}
            >
              <GatsbyImage
                image={data.podcast.image.asset.gatsbyImageData}
                alt={data.podcast.image.asset.altText || data.podcast.title}
                className="radio_station_tatoo10 img-fluid"
                loading="eager"
                objectFit="contain"
              />
            </div>{" "}
            <br />
            <div
              onClick={() => handleClick(data.weird)}
              style={{
                cursor: "pointer",
              }}
            >
              <GatsbyImage
                image={data.weird.image.asset.gatsbyImageData}
                alt={data.weird.image.asset.altText || data.weird.title}
                className="radio_station_tatoo13 img-fluid"
                loading="eager"
                objectFit="contain"
              />
            </div>
          </div>
          <div
            className="col-lg-3 col-md-3"
            style={{
              cursor: "pointer",
            }}
          >
            <GatsbyImage
              image={data.musicIcon3.asset.gatsbyImageData}
              alt={data.musicIcon3.asset.altText || "Music Icon 3"}
              className="radio_station_tatoo11 img-fluid"
              loading="eager"
              objectFit="contain"
            />
          </div>
        </div>
      </div>
      <Modal open={openModal} onClose={handleClose} url={audioUrl} />
    </div>
  );
};

export default RadioStationDesktop;
