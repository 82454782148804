import React from "react";
import { graphql } from "gatsby";
import RadioStationDesktop from "../components/radiostation/Desktop";
import RadioStationMobile from "../components/radiostation/Mobile";
import "../css/radiostation.css";
import useDimensions from "../hooks/useDimensions";

const RadioStation = ({ data }) => {
  const [width] = useDimensions();
  const radioStation = data.sanityPage.radioStationTemp;
  const seo = data.sanityPage.seo;

  return (
    <div className="radio_station_bg">
      {width &&
        (width > 768 ? (
          <RadioStationDesktop data={radioStation} />
        ) : (
          <RadioStationMobile data={radioStation} />
        ))}
    </div>
  );
};

export const query = graphql`
  query {
    sanityPage(slug: { current: { eq: "/radio-station" } }) {
      title
      template
      slug {
        current
      }
      seo {
        title
        pagehandle
        ldSchema
        keywords
        description
      }
      radioStationTemp {
        back {
          asset {
            altText
            gatsbyImageData(placeholder: BLURRED, formats: WEBP)
          }
        }
        logo {
          asset {
            altText
            gatsbyImageData(placeholder: BLURRED, formats: WEBP)
          }
        }
        logo2 {
          asset {
            altText
            gatsbyImageData(placeholder: BLURRED, formats: WEBP)
          }
        }
        musicIcon1 {
          asset {
            altText
            gatsbyImageData(placeholder: BLURRED, formats: WEBP)
          }
        }
        musicIcon2 {
          asset {
            altText
            gatsbyImageData(placeholder: BLURRED, formats: WEBP)
          }
        }
        musicIcon3 {
          asset {
            altText
            gatsbyImageData(placeholder: BLURRED, formats: WEBP)
          }
        }
        poePoems {
          title
          isModal
          image {
            asset {
              altText
              gatsbyImageData(placeholder: BLURRED, formats: WEBP)
            }
          }
          audio {
            asset {
              url
            }
          }
          externalUrl
        }
        anabelLee {
          title
          isModal
          image {
            asset {
              altText
              gatsbyImageData(placeholder: BLURRED, formats: WEBP)
            }
          }
          audio {
            asset {
              url
            }
          }
          externalUrl
        }
        toonDeaf {
          title
          isModal
          image {
            asset {
              altText
              gatsbyImageData(placeholder: BLURRED, formats: WEBP)
            }
          }
          audio {
            asset {
              url
            }
          }
          externalUrl
        }
        goldenGirls {
          title
          isModal
          image {
            asset {
              altText
              gatsbyImageData(placeholder: BLURRED, formats: WEBP)
            }
          }
          audio {
            asset {
              url
            }
          }
          externalUrl
        }
        singAlone {
          title
          isModal
          image {
            asset {
              altText
              gatsbyImageData(placeholder: BLURRED, formats: WEBP)
            }
          }
          audio {
            asset {
              url
            }
          }
          externalUrl
        }
        dontTouch {
          title
          isModal
          image {
            asset {
              altText
              gatsbyImageData(placeholder: BLURRED, formats: WEBP)
            }
          }
          audio {
            asset {
              url
            }
          }
          externalUrl
        }
        podcast {
          title
          isModal
          image {
            asset {
              altText
              gatsbyImageData(placeholder: BLURRED, formats: WEBP)
            }
          }
          audio {
            asset {
              url
            }
          }
          externalUrl
        }
        weird {
          title
          isModal
          image {
            asset {
              altText
              gatsbyImageData(placeholder: BLURRED, formats: WEBP)
            }
          }
          audio {
            asset {
              url
            }
          }
          externalUrl
        }
      }
    }
  }
`;
export default RadioStation;
