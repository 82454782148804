import React, { useEffect, useRef, useState } from "react";

const Modal = ({ open, onClose, url }) => {
  const myRef = useRef();
  console.log("Modal url: ", url);
  useEffect(() => {
    if (open) {
      myRef.current.play();
    } else {
      myRef.current.pause();
    }
  }, [open]);
  return (
    <div
      id="anabel-lee"
      className={`modal ${!open ? "fade" : ""}`}
      style={{
        display: `${!open ? "none" : "block"}`,
      }}
      role="dialog"
      onClick={onClose}
    >
      <div className="modal-dialog-radio_station" onClick={onClose}>
        <div className="modal-content">
          <div className="modal-body">
            <audio
              id="anabel"
              ref={myRef}
              src={url}
              preload="none"
              className="radio_audio"
              autoPlay
              controls
              playsInline
            ></audio>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
